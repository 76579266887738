@media print {
  .pageWrapper {
    height: max-content !important;
    min-height: unset !important;
  }
  .pageInnerWrapper {
    width: 100% !important;
    margin: 0 !important;
    padding-bottom: 0 !important;
  }
}
