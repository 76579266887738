.tableContainer {
  max-height: calc(100vh - 220px);
}

.tableContainerViewer {
  max-height: calc(100vh - 170px);
}

@media screen {
  .summaryHeader {
    display: none !important;
  }
}
